import React from "react";
import { useLottie } from "lottie-react";

import animationData from "../assets/lottie/Lottie_logo.json";
import { Overlay, Container } from "./styles/fullScreenLoader";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
};
const FullScreenLoader = ({ opacity = 1 }) => {
  const { View } = useLottie(defaultOptions);

  return (
    <>
      <Overlay opacity={opacity} />
      <Container>
        <>{View}</>
      </Container>
    </>
  );
};

export default FullScreenLoader;
