import React, { useContext, useState } from "react";
import { MainWrapper } from "../styles/IntroStyle";
import { CloseBtn, PrintSurpriseMe, PrintSurpriseMeWrap } from "../styles/editStyle";
import { SurpriseMeContent } from "../styles/libraryStyle";
import { PrintedTitle, Print, InstructionSurpriseMeText } from "../styles/IntroStyle";
import { Phrase } from "../Phrase";
import { ContentContext, LibraryContext, MainContext } from "../../context";
import { handleHistoryPush, handleHistoryReplace } from "../../utils/history";
import { Loader } from "../styles/loader";
import { sendEvent, surpriseMeFlow, uploadModule } from "../../actions";
import { BadConnectionMsg } from "../messages";
import { downloadImageToBlob } from "../common/imageBlobHelper";
import { createOneMacaronRipple } from "../MacaronSelection/utils/createMacaronRipple";
import SurpriseMeIcon from "./SurpriseMeIcon";

export default function Surprise(props) {
  const { library } = useContext(LibraryContext);
  const { content } = useContext(ContentContext);
  const { main } = useContext(MainContext);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);
  const [error, setError] = useState("");
  const { surprise } = library || {};
  const { general, edit, home } = content || {};
  if (!content) {
    handleHistoryReplace(props.history, '/home')
    return null;
  }

  const IFrame = props.location.state.iFrame;
  const tokenHeader = `android_1.17_fAxTeOoIu1HoZRmrBgCKzCSBamFls7Z4mp3ioUIyEEe8o9CTDweURADrWZ3N`;

  // colors
  const buttonBackground = home.buttons.snap ? home.buttons.snap.backgroundColor : home.buttons.library.backgroundColor;
  const buttonTextColor = content.home.buttons.snap ? content.home.buttons.snap.textColor : content.home.buttons.library.textColor;
  const loaderColor = buttonTextColor;

  const newColors = { ...general.colors, buttonBackground };

  //Text
  const { instruction1, LetsStartText: printLetsStart } = home.texts.instructions || "";
  const { printButton: printButtonStyle, seeItButton } = edit.buttons || {};
  const { surpriseMe, amazingDesign } = edit.texts.instructions;

  let surepriseImage = () => {
    let totalLength = surprise.length;
    let i = Math.floor(Math.random() * totalLength);
    return surprise[i];
  };

  if (props.location.state.macaronId) {
    const {macarons = {}, macaronId, macaronAmount, iframe} = props.location.state;
    if (main.preview) {
      handleHistoryPush(props.history, "/macaron", {
        ...props.location.state,
        macarons: {...macarons, [macaronId.toString()]: {image: 'stub', isSurprise: true}},
        macaronAmount,
        iframe
      });
    } else {
      const randImage = surepriseImage();

      handleHistoryPush(props.history, "/macaron", {
        ...props.location.state,
        macarons: {...macarons, [macaronId.toString()]: {image: randImage.rippleImage.url, isSurprise: true}},
        macaronAmount,
        iframe
      });

    }
  }

  const onPrint = async () => {
    if (main.preview) {
      handleHistoryReplace(props.history, "/result", {
        number: 25,
        mobileQData: "",
      });
      return;
    };
    const randImage = surepriseImage();
    const randImageUrl = randImage.rippleImage.url
    setIsSubmitButtonDisabled(true);

    if (props.location.state.isOneMacaron) {
      const ripple = await createOneMacaronRipple(randImageUrl)
      const mobileQData =
      await uploadModule({ content, tokenHeader, croppedImg: ripple, isMacaroon: true}).catch((err) => {
        setIsSubmitButtonDisabled(false);
        setError("connection error");
      })

      handleHistoryPush(props.history, "/result", {
        number: mobileQData.ordinal || 1,
      });
      return;
    }
    
    if (IFrame) {
      const imageBase64 = await downloadImageToBlob(randImageUrl);
      const message = JSON.stringify({
        imageBase64,
        type: "image",
        isMacaroon: !!props.location.state.isOneMacaron
      });
      window.parent.postMessage(message, "*");
      return;
    }

    const mobileQData = await surpriseMeFlow({ surpriseImage: randImage, content }).catch((err) => {
      setIsSubmitButtonDisabled(false);
      setError("connection error");
      return;
    });

    sendEvent({
      eventName: "surpriseMeButton",
      location: content.locationId,
      mode: main.mode,
      mobileq: mobileQData,
      status: "done",
    });

    handleHistoryPush(props.history, "/result", {
      number: mobileQData.ordinal || 1,
    });
  };

  function goToHome() {
    if (main.preview) {
      handleHistoryReplace(props.history, "/preview");
      return;
    }

    handleHistoryPush(props.history, "/home");
  }

  return (
    <MainWrapper backgroundColor={content.general.colors.backgroundColor}>
      {!IFrame && <CloseBtn onClick={goToHome} color={newColors.generalTextColor} position="left" />}
      <SurpriseMeContent>
        <SurpriseMeIcon stroke={newColors.generalTextColor}/>
        <Print>
          <PrintedTitle id="printed" data={printLetsStart}>
            <Phrase Key={surpriseMe ? surpriseMe.translationKey : "a8522058-ee56-414a-9788-3120a3c06b8a"} default={surpriseMe && surpriseMe.text ? surpriseMe.text : ""} />
          </PrintedTitle>
          <InstructionSurpriseMeText id="picor" data={instruction1}>
            <Phrase Key={amazingDesign ? amazingDesign.translationKey : "5ac64736-31b4-491b-bd42-ac365ea27cad"} default={amazingDesign && amazingDesign.text ? amazingDesign.text : ""} />
          </InstructionSurpriseMeText>
        </Print>
      </SurpriseMeContent>
      <PrintSurpriseMeWrap id="PrintSurpriseMeWrap">
        <PrintSurpriseMe id="printButton" data={printButtonStyle} onClick={onPrint} disabled={isSubmitButtonDisabled}>
          {!isSubmitButtonDisabled ? (
            <Phrase Key={seeItButton ? seeItButton.translationKey : "81138922-8590-4f6a-ba86-943475a754e8"} default={seeItButton && seeItButton.text ? seeItButton.text : "LET’S SEE IT!"} />
          ) : (
            <Loader color={loaderColor} />
          )}
        </PrintSurpriseMe>
      </PrintSurpriseMeWrap>
      {!!error && <BadConnectionMsg data={content.messages.texts.connectionError} setError={setError} back={general.colors} />}
    </MainWrapper>
  );
}
